/* --------------------------------------------------------------------------------------
  onTap.directive.js
  Copyright © 2024 Xerox Corporation. All Rights Reserved.

  Copyright protection claimed includes all forms and matters of copyrightable material
  and information now allowed by statutory or judicial law or hereinafter granted,
  including without limitation, material generated from the software programs which
  are displayed on the screen such as icons, screen display looks, etc.
  -------------------------------------------------------------------------------------
*/

export class onTap {
  constructor($parse) {
    this.restrict = 'A'
    this.$parse = $parse
  }
  link(scope, elem, attrs) {
    elem.bind('mousedown', e => {      
      this.originalX = e.clientX
      this.originalY = e.clientY
      this.startTime = new Date().getTime()
    })
    elem.bind('mouseup', e => {
      const endTime = new Date().getTime()
      const deltaX = Math.abs(this.originalX - e.clientX)
      const deltaY = Math.abs(this.originalY - e.clientY)
      const timeDiff = endTime - this.startTime

      const isTap = deltaX < 5 && deltaY < 5 && timeDiff < 300

      if (isTap) {
        document.activeElement.blur()
        const func = this.$parse(attrs.onTap)        
        scope.$apply(() => {
          func(scope)
        })
      }      
    })
  }
  static directiveFactory($parse) {
    onTap.instance = new onTap($parse)
    return onTap.instance
  }
}
