/* --------------------------------------------------------------------------------------
   custom.component.js
   Copyright © 2024 Xerox Corporation. All Rights Reserved.

   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
   -------------------------------------------------------------------------------------
*/

export const customComponent = {
  template: require('./custom.html'),
  controller: 'customCtrl',
  controllerAs: 'vm'
}

export class customCtrl {
  /*@ngInject*/
  constructor(commonService, customService, dataService, deviceInfoService, sessionDataService, persistService, $state, $translate, $timeout, $scope) {
    this.commonService = commonService
    this.customService = customService
    this.dataService = dataService
    this.deviceInfoService = deviceInfoService
    this.sessionDataService = sessionDataService
    this.persistService = persistService
    this.$state = $state
    this.$translate = $translate
    this.$timeout = $timeout
    this.$scope = $scope
  }
  async $onInit() {
    this.loading = true
    this.commonService.setDefaultBackground()
    try {
      await this.loadInitialData()
      await this.loadPresetData()

      this.setScrollable()
      this.$scope.$watch(
        () => this.preset,
        (newValue, oldValue) => {
          if (newValue !== oldValue) {
            if (this.isPresetValid(newValue)) {
              this.disableNextButton(false);
            }
            else {
              //disable
              this.disableNextButton(true);
            }
          }
        },
        true  // Setting this to true for deep watch
      );

    } catch (e) {
      this.error = await this.commonService.handleError(e)
    } finally {
      if (this.isPresetValid(this.preset)) {
        this.disableNextButton(false);
      }
      else {
        //disable
        this.disableNextButton(true);
      }
      this.loading = false
      this.commonService.updateView()
    }
  }

  async loadInitialData() {
    this.deviceInfo = await this.deviceInfoService.get()
    this.locations = this.commonService.getCountries()
    this.addedMatchCriteria = 'contains'
    this.addedMatchCriteriaText = await this.$translate('WORDS_CONTAINING')
    this.preset = angular.copy(this.sessionDataService.preset)
    
    this.selectedLocation = this.commonService.getDeviceLocation()
    this.selectedLocationStandardFields = await this.getStandardFields()
    if (!this.preset) {
      const customPresetName = await this.$translate('CUSTOM')
      this.defaultCustomPreset = this.getDefaultCustomPreset(customPresetName)
    } else if (this.preset.new) {
      // Only when coming back from scan screen
      this.enableSave()
    } 
    await this.customService.updateBalance(this.sessionDataService.appId, this.sessionDataService.deviceId)
    
  }
  getDefaultCustomPreset(name) {
    return {
      Name: name,
      CustomFields: [],
      StandardFields: [...this.selectedLocationStandardFields]
    }
  }
  async loadPresetData() {
    if (!this.preset)
      this.preset = this.getDefaultCustomPreset()

    if (this.preset.Country)
      this.selectedLocation = this.locations.find(value => value.code.toLowerCase() == this.preset.Country.toLowerCase())
  }
  async checkCustomFieldSpacing() {
    this.addedCustomFieldText = angular.element('#custom-word-input').getkeyboard().$preview.val()
    this.addedCustomFieldTextBackup = this.addedCustomFieldText
    //// HACK: check how to do this by actionBar
    angular.element("[aria-describedby='add-custom-xrx-popup']")
      .find('.ui-xrxpopup-titlebar-close')
      .xrxbutton({ disabled: !!!this.addedCustomFieldText })

    if (this.addedCustomFieldText.includes(' ')) {
      this.addedMatchCriteria = 'match'
      this.addedMatchCriteriaText = await this.$translate('WORDS_MATCHING')
    }
  }
  updateCustomFieldNewValue() {
    this.addedCustomFieldText = this.addedCustomFieldTextBackup
    this.commonService.updateView()
  }
  async selectMatchCriteria(item) {
    this.addedMatchCriteria = item.value
    this.addedMatchCriteriaText = item.label
    await this.commonService.updateView()
  }
  addCustomFieldClosed() {
    if (this.addedCustomFieldText) {
      this.preset.CustomFields.push({
        Text: this.addedCustomFieldText,
        DeviceGuid: this.deviceInfo.serial,
        Contains: !!(this.addedMatchCriteria == 'contains')
      })
      this.enableSave()
    }
    this.addedCustomFieldText = null
    this.addedCustomFieldTextBackup = null
    this.addCustomField = false
    this.commonService.updateView()
    this.refreshScroll()
  }
  addCustomFieldCancelled() {
    this.addedCustomFieldText = null
    this.addedCustomFieldTextBackup = null
    this.addCustomField = false
    this.commonService.updateView()
  }
  setScrollable() {
    this.$timeout(() => angular.element('#scroll-container').xrxscrollable({ theme: 'violet' }))
  }
  getStandardFields() {
    return this.dataService.getStandardFields(this.selectedLocation.code, this.deviceInfo.serial)
  }
  refreshScroll() {
    this.commonService.updateView()
    angular.element('#scroll-container').xrxscrollable('refresh')
    angular.element("#scroll-container").xrxscrollable("getIScroll").scrollTo(0, 0)
  }
  showAddCustomField() {
    this.addCustomField = true
    this.commonService.updateView()
    angular.element("#custom-word-input").getkeyboard().reveal()
    //// HACK: check how to do this by actionBar
    angular.element("[aria-describedby='add-custom-xrx-popup']")
      .find('.ui-xrxpopup-titlebar-close')
      .xrxbutton({ disabled: true })
  }
  enableSave() {
    angular.element('#save-preset-button').xrxbutton({ disabled: false })
  }
  disableSave() {
    angular.element('#save-preset-button').xrxbutton({ disabled: true })
  }
  disableNextButton(disable) {
    angular.element('#next-button').xrxbutton({ disabled: disable })
  }
  isPresetValid(preset) {
    if (Object.keys(preset).length === 0) return false
    return preset.CustomFields.length > 0 || preset.StandardFields.some(c => c.IsToggled == true)
  }
  async reset() {
    if (this.preset.saved) {
      this.preset = angular.copy(this.sessionDataService.originalPreset)
    } else {
      this.preset = this.getDefaultCustomPreset()
      // TODO: This is a hack but we need to refactor preset management completely
      for (let i = 0; i < this.preset.StandardFields.length; i++) {
        this.preset.StandardFields[i].IsToggled = false;
      }
    }

    await this.loadPresetData()
    this.resetTime = new Date()
    await this.commonService.updateView()
    this.refreshScroll()
  }

  async loadSelectInfotypes() {
    this.loading = true
    this.infotypes = await this.dataService.getInfotypes(this.selectedLocation.code)
    this.infotypes.forEach(infotype => infotype.selected = !!this.preset.StandardFields.find(stf => stf.GoogleId == infotype.GoogleId))
    this.loading = false
    this.commonService.updateView()
  }
  openSelectLocation() {
    this.showSelectLocation = true
    this.selectedLocationTemp = this.selectedLocation
    this.commonService.updateView()
  }
  selectLocation(item) {
    this.selectedLocationTemp = this.locations.find(l => l.code == item.value)
    this.commonService.updateView()
  }
  async selectLocationClosed() {
    this.selectedLocation = this.selectedLocationTemp
    this.selectedLocationStandardFields = await this.getStandardFields()
    this.preset.StandardFields = [...this.selectedLocationStandardFields]
    this.enableSave()
    this.showSelectLocation = false
    this.commonService.updateLocation(this.selectedLocation);
    this.removeInfotypesPopup()
    setTimeout(() => {
      this.$state.reload()
    });
  }
  selectLocationCancel() {
    this.showSelectLocation = false
    this.commonService.updateView()
  }
  async savePreset() {
    this.overwriteAlertPresetName = this.preset.Name
    if (this.preset.Id || this.preset.IsCustom) {
      this.matchingPreset = this.preset
      this.showOverwriteAlert = true
    }
    else {
      await this.commonService.updateView()
      this.showSavePreset = true
      await this.commonService.updateView()
    }
  }
  async saveAsNewPreset() {
    this.isSavingAsNew = true
    this.showOverwriteAlert = false
    await this.commonService.updateView()
    this.showSavePreset = true
    await this.commonService.updateView()
  }
  async overwrite() {
    this.showOverwriteAlert = false
    this.preset.Id = this.matchingPreset.Id
    this.preset.Name = this.matchingPreset.Name
    this.preset.IsCustom = true
    this.preset.DeviceGuid = this.deviceInfo.serial
    this.preset.Country = this.selectedLocation.code

    await this.dataService.patchPreset(this.preset)
    this.matchingPreset = null
    this.disableSave()
  }
  async savePresetClose(presetName) {
    if (this.defaultCustomPreset && presetName.toLowerCase() === this.defaultCustomPreset.Name.toLowerCase()) {
      return;
    }
    else {
      this.matchingPreset = this.sessionDataService.presets.find(preset => preset.Name.toLowerCase() == presetName.toLowerCase())
      if (this.matchingPreset) {
        this.showOverwriteAlert = true
        this.commonService.updateView()
      }
      else {
        if (this.isSavingAsNew) {
          this.preset.Id = null
          this.isSavingAsNew = null
        }
        this.preset.IsCustom = true
        this.preset.DeviceGuid = this.deviceInfo.serial
        this.preset.Name = presetName
        this.preset.Country = this.selectedLocation.code
        await this.dataService.postPreset(this.preset).then(result => this.preset.Id = result)
        this.sessionDataService.presets = await this.dataService.getPresets(this.deviceInfo.serial)
        this.showSavePreset = false
        this.commonService.updateView()
      }
      this.commonService.updateLocation(this.selectedLocation)
      this.disableSave()
    }
  }
  next() {
    this.sessionDataService.preset = angular.copy(this.preset)
    this.sessionDataService.preset.new = !this.sessionDataService.preset.saved
    this.removeInfotypesPopup()
    this.commonService.updateLocation(this.selectedLocation)
    this.$state.go('scan')
  }
  back() {
    this.removeInfotypesPopup()
    this.sessionDataService.preset = null
    this.$state.go('main')
  }
  removeInfotypesPopup() {
    try {
      angular.element('#infotypes-popup').remove()
    } catch (e) {
      console.log(e)
    }
  }
  customFieldDeleted() {
    this.refreshScroll()
    this.enableSave()
  }
  errorClosed() {
    this.commonService.exitApp()
  }
}
