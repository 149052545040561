/* --------------------------------------------------------------------------------------
   presets.component.js
   Copyright © 2024 Xerox Corporation. All Rights Reserved.

   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
   -------------------------------------------------------------------------------------
*/

export const presetsComponent = {
  template: require('./presets.html'),
  controller: 'presetsCtrl',
  controllerAs: 'vm'
}

export class presetsCtrl {
  constructor (commonService, dataService, deviceInfoService, sessionDataService, $translate, $state, $timeout) {
    this.commonService = commonService
    this.dataService = dataService
    this.deviceInfoService = deviceInfoService
    this.sessionDataService = sessionDataService
    this.$translate = $translate
    this.$state = $state
    this.$timeout = $timeout
  }
  async $onInit() {
    this.commonService.setDefaultBackground()
    const deviceInfo = await this.deviceInfoService.get()
    this.presets = this.sessionDataService.presets
    this.screenSize = this.commonService.getScreenSize()
    this.presetListHeight = this.getPresetListHeight(this.presets.length)
    this.refreshBoundingBox()

    this.deleteAlertButtons = [{
        alertIcon: 'glyphicon-close',
        text: await this.$translate('CANCEL'),
        click: () => this.cancelDelete()
      },{
        alertIcon: 'glyphicon-trash',
        text: await this.$translate('DELETE'),
        click: () => this.deletePreset()
      }
    ]

    if (this.presets.length == 1)
      this.selectPreset(this.presets[0], true)
  }
  getPresetListHeight(rows) {
    const calcHeight = 72 * rows
    const maxHeight = this.screenSize == 7? 388 : 510
    return (calcHeight < maxHeight)? calcHeight : maxHeight
  }
  selectPreset(preset, onInit) {
    if (!onInit)
      angular.element('#ok-button').xrxbutton({disabled: false})
    else {
      this.$timeout(() => angular.element('#ok-button').xrxbutton({disabled: false}), 100)
    }
    this.preset = preset
    this.commonService.updateView()
    this.refreshBoundingBox()
  }
  refreshBoundingBox() {
    this.bboxHeight = (this.screenSize == 7)?  388: 505
    this.bboxWidth = (this.screenSize === 7)? 317: 407

    const frameHeight = angular.element('#preset-description-box').height()
    const contentHeight = angular.element('#preset-description-box-wrapper').height()
    const isScrollable = (frameHeight < contentHeight)
    //angular.element('#preset-description-box').xrxboundingbox().destroy()
    angular.element('#preset-description-box').xrxboundingbox({height: this.bboxHeight, width: this.bboxWidth, boundingScroll: isScrollable})
  }
  deletePresetConfirm(preset) {
    this.presetToDelete = preset
  }
  cancelDelete() {
    this.presetToDelete = null
    this.commonService.updateView()
  }
  async deletePreset() {
    await this.dataService.deletePreset(this.presetToDelete.Id)
    this.deviceInfo = await this.deviceInfoService.get()
    this.presets = await this.dataService.getPresets(this.deviceInfo.serial)
    this.sessionDataService.presets = this.presets
    if (!this.presets.length)
      this.$state.go('custom')
    else {
      this.$state.reload()
    }
  }
  submit() {
    this.sessionDataService.preset = {...this.preset, saved: true}
    //workaround for reset
    this.sessionDataService.originalPreset = angular.copy(this.sessionDataService.preset)
    this.$state.go('custom')
  }
}
